<template>
    <div class="main-container">
        <div class="partners-container">
            <div class="partner-card">
                <div>
                    <v-img :width="415" :height="415" class="portrait" :src="require('@/assets/partners/Photo-D-Savvas.jpg')" />
                </div>
                <div class="name mt-4">Δημήτρης Σάββας</div>
                <div class="mt-16 mb-6">
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        @click="$router.push({ name: 'partners_DSavvas', replace: true })"
                    >
                        Read CV
                    </v-btn>
                </div>
            </div>
            <div class="partner-card">
                <div>
                    <v-img :width="415" :height="415" class="portrait" :src="require('@/assets/partners/Photo_Georgia_Ntatsi.jpg')" />
                </div>
                <div class="name mt-4">Γεωργία Ντάτση</div>
                <div class="mt-16 mb-6">
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        @click="$router.push({ name: 'partners_ntatsi', replace: true })"
                    >
                        Read CV
                    </v-btn>
                </div>
            </div>
            <div class="partner-card">
                <div>
                    <v-img :width="415" :height="415" class="portrait" :src="require('@/assets/partners/Photo-I-Karavidas.jpg')" />
                </div>
                <div class="name mt-4">Ιωάννης Καραβίδας</div>
                <div class="mt-16 mb-6">
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        @click="$router.push({ name: 'partners_Karavidas', replace: true })"
                    >
                        Read CV
                    </v-btn>
                </div>
            </div>
            <div class="partner-card">
                <div>
                    <v-img :width="415" :height="415" class="portrait" :src="require('@/assets/partners/Photo-Theodora_Ntanasi.jpg')" />
                </div>
                <div class="name mt-4">Θεοδώρα Ντάναση</div>
                <div class="mt-16 mb-6">
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        @click="$router.push({ name: 'partners_Ntanasi', replace: true })"
                    >
                        Read CV
                    </v-btn>
                </div>
            </div>
            <div class="partner-card">
                <div>
                    <v-img :width="415" :height="415" class="portrait" :src="require('@/assets/partners/Photo-E-Giannothanasis.jpg')" />
                </div>
                <div class="name mt-4">Ευάγγελος Γιαννοθανάσης</div>
                <div class="mt-16 mb-6">
                    <v-btn
                        elevation="0"
                        color="#6BB00B"
                        @click="$router.push({ name: 'partners_giannothanasis', replace: true })"
                    >
                        Read CV
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.main-container {
    max-width: 80vw;
    margin: 50px auto;
    padding-left: 80px;
}
.partners-container {
    display: flex;
    flex-flow: row wrap;
    justify-csontent: center;
    gap: 50px;
    max-width: 80vw;
    margin: auto;
    align-content: flex-start;
}
.partner-card {
    width: 415px;
    border: 1px solid #D9D9D9;
    border-radius: 25px;
    overflow: hidden;
    text-align: center;
}
.portrait {
    border-radius: 25px;
}
.name {
    font-size: 24px;
    font-weight: 700;
}

.v-btn {
    color: #fff!important;
    font-weight: 400;
    font-size: 16px!important;
    border-radius: 10px!important;
    padding-top: 20px!important;
    padding-bottom: 20px!important;
    height: 45px!important;
    width: 200px;
}
</style>
